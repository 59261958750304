<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="가스항목 목록"
      tableId="gas"
      :columns="grid.columns"
      :data="grid.data"
      :filtering="false"
      :columnSetting="false"
      :editable="editable"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" :showLoading="false" label="추가" icon="add" @btnClicked="add" />
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="PUT"
            label="저장" 
            icon="save" 
            @beforeAction="saveGas"
            @btnCallback="saveGasCallback"/>
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-gas-item',
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      grid: {
        columns: [],
        data: [],
      },
      useFlagItems: [],
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.swp.gas.list.url
      this.saveUrl = transactionConfig.sop.swp.gas.save.url
      // code setting
      this.$comm.getComboItems('SOP_WORK_TYPE_CD').then(_result => {
        _result = this.$_.filter(_result, { attrVal1: 'Y' })
        this.grid.columns = [
          {
            name: 'plantCd',
            field: 'plantCd',
            label: '사업장',
            align: 'center',
            sortable: true,
            required: true,
            style: 'width:100px',
            type: 'plant',
          },
          {
            name: 'gasName',
            field: 'gasName',
            label: '가스항목명',
            align: 'left',
            sortable: true,
            required: true,
            style: 'width:300px',
            type: 'text',
          },
          {
            name: 'safetyStandards',
            field: 'safetyStandards',
            label: '안전기준',
            align: 'center',
            sortable: true,
            style: 'width:150px',
            type: 'text',
          },
          {
            name: 'workTypes',
            field: 'workTypes',
            label: '작업종류',
            align: 'left',
            sortable: true,
            required: true,
            style: 'width:300px',
            type: 'multiSelect',
            isObject: true,
            valueText: 'sopWorkTypeName',
            valueKey: 'sopWorkTypeCd',
            itemText: 'codeName',
            itemValue: 'code',
            comboItems: _result,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            sortable: true,
            style: 'width:100px',
            type: 'check',
            true: 'Y',
            false: 'N',
          },
        ]
      });
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    add() {
      let date = new Date();
      if (!this.grid.data) {
        this.grid.data = [];
      }
      this.grid.data.splice(0, 0, {
        sopGasId: String(date.getTime()),  // 가스번호
        plantCd: null,  // 사업장 코드
        gasName: '',  // 가스항목명
        safetyStandards: '',  // 안전기준
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        workTypes: [],
        editFlag: 'C'
      })
    },
    saveGas() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, item => {
              item.regUserId = this.$store.getters.user.userId
              item.chgUserId = this.$store.getters.user.userId
            });
            
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveGasCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
